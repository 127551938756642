<template>
  <div class="mt-4">
    <div class="form--group" :class="{ 'has-error': validation.hasError('fullName') }">
      <div class="left--col">
        <label for="fullname">
          <span>{{ $t('profile.name') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <input id="fullname" v-model="fullName" class="basic--input" />
        <span class="val-error mt-2" v-if="validation.hasError('fullName')">{{
          validation.firstError('fullName')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('ktp') }">
      <div class="left--col">
        <label for="ktp">
          <span>{{ $t('profile.ktpR') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <input id="ktp" @keypress="isNumber" v-model="ktp" class="basic--input" />
        <span class="val-error mt-2" v-if="validation.hasError('ktp')">{{
          validation.firstError('ktp')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('phone') }">
      <div class="left--col">
        <label for="phone">
          <span>{{ $t('profile.phone') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <!--        <cleave-->
        <!--          id="phone"-->
        <!--          v-model="phone"-->
        <!--          :options="phoneCleaveOption"-->
        <!--          class="basic&#45;&#45;input"-->
        <!--          type="text"-->
        <!--          name="phone"-->
        <!--        />-->
        <client-only>
          <vue-tel-input
            id="phone"
            :value="phone ? phone : ''"
            @input="phoneInput"
            :validCharactersOnly="true"
            defaultCountry="sg"
            class="basic--input"
            style="padding: 4px;"
          />
        </client-only>
        <span class="val-error mt-2" v-if="validation.hasError('phone')">{{
          validation.firstError('phone')
        }}</span>
      </div>
    </div>
    <div class="form--group" :class="{ 'has-error': validation.hasError('email') }">
      <div class="left--col">
        <label for="email">
          <span>{{ $t('loginRegister.email') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <input id="email" type="email" v-model="email" class="basic--input" />
        <span class="val-error mt-2" v-if="validation.hasError('email')">{{
          validation.firstError('email')
        }}</span>
      </div>
    </div>
    <!--    <div class="form&#45;&#45;group" :class="{ 'has-error': validation.hasError('address') }">-->
    <!--      <div class="left&#45;&#45;col">-->
    <!--        <label for="address">-->
    <!--          <span>{{ $t('profile.address2') }}</span>-->
    <!--          <span class="required">*</span>-->
    <!--        </label>-->
    <!--      </div>-->
    <!--      <div class="right&#45;&#45;col">-->
    <!--        <textarea id="address" v-model="address" class="basic&#45;&#45;input" rows="3" />-->
    <!--        <span class="val-error mt-2" v-if="validation.hasError('address')">{{-->
    <!--          validation.firstError('address')-->
    <!--        }}</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <regional-form-->
    <!--      @loaded="regionalFormLoaded"-->
    <!--      ref="regionalForm"-->
    <!--      :village-required="true"-->
    <!--    ></regional-form>-->
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';

// const RegionalForm = () => import('@/components/utils/partials/regional-form.vue');
import { mapState } from 'vuex';

// import Cleave from 'vue-cleave-component';
// import 'cleave.js/dist/addons/cleave-phone.id.js';
// import Button from "../installment/button";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
export default {
  name: 'billing-information',
  components: {
    // Button,
    // RegionalForm,
    // Cleave,
    VueTelInput,
  },
  data() {
    return {
      phoneCleaveOption: {
        phone: true,
        phoneRegionCode: 'id',
      },
    };
  },
  computed: {
    ...mapState({
      user: state => state.global.user,
      initRegionalData: state => state.checkout.billingInfo.initRegionalData,
    }),
    address: {
      get() {
        return this.$store.state.checkout.billingInfo.address;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_ADDRESS', val);
      },
    },
    fullName: {
      get() {
        return this.$store.state.checkout.billingInfo.fullName;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_FULL_NAME', val);
      },
    },
    ktp: {
      get() {
        return this.$store.state.checkout.billingInfo.ktp;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_KTP', val);
      },
    },
    phone: {
      get() {
        return this.$store.state.checkout.billingInfo.phone;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_PHONE', val);
      },
    },
    phoneCountryCode: {
      get() {
        return this.$store.state.checkout.billingInfo.phoneCountryCode;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_PHONE_COUNTRY_CODE', val);
      },
    },
    email: {
      get() {
        return this.$store.state.checkout.billingInfo.email;
      },
      set(val) {
        this.$store.commit('checkout/billingInfo/SET_EMAIL', val);
      },
    },
    loading: {
      get() {
        return this.$store.state.v2.booking.billingInfoLoading;
      },
      set(val) {
        this.$store.commit('v2/booking/SET_BILLING_INFO_LOADING', val);
      },
    },
  },
  validators: {
    fullName(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.name.required'))
        .maxLength(50, this.$i18n.t('errors.name.max', { maxChar: 50 }));
    },
    ktp(value) {
      return Validator.value(value).required(this.$i18n.t('errors.identity_card_number.required'));
      // .length(16, this.$i18n.t('errors.identity_card_number.length', { char: 16 }));
    },
    phone(value) {
      value = value !== undefined ? value : null;
      return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
    },
    // address(value) {
    //   return Validator.value(value)
    //     .required(this.$i18n.t('errors.address.required'))
    //     .maxLength(250, this.$i18n.t('errors.address.max', { maxChar: 250 }));
    // },
    email(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.email.required'))
        .email(this.$i18n.t('errors.loginRegister.email.invalid'))
        .maxLength(255, this.$i18n.t('errors.loginRegister.email.max', { maxChar: 255 }));
    },
  },
  methods: {
    regionalFormLoaded() {
      this.applyRegionalData();
    },
    phoneInput(phone, phoneObject) {
      if (phoneObject && phoneObject.countryCode) {
        this.phoneCountryCode = phoneObject.countryCode;
      } else {
        this.phoneCountryCode = null;
      }
      this.phone = phone;
    },
    validate() {
      return this.$validate();
      // let validationArray = [this.$validate(), this.$refs.regionalForm.validate()];
      // return Promise.all(validationArray).then(function(results) {
      //   return (
      //     results.filter(function(result) {
      //       return !result;
      //     }).length === 0
      //   );
      // });
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async applyRegionalData() {
      this.$refs.regionalForm.provinces = this.initRegionalData.provinces;
      this.$refs.regionalForm.cities = this.initRegionalData.cities;
      this.$refs.regionalForm.districts = this.initRegionalData.districts;
      this.$refs.regionalForm.villages = this.initRegionalData.villages;

      if (this.initRegionalData.province != null) {
        this.$refs.regionalForm.province = this.initRegionalData.provinces.find(item => {
          return item.id === this.initRegionalData.province;
        });
      }
      if (this.initRegionalData.city != null) {
        this.$refs.regionalForm.city = this.initRegionalData.cities.find(item => {
          return item.id === this.initRegionalData.city;
        });
      }
      if (this.initRegionalData.district != null) {
        this.$refs.regionalForm.district = this.initRegionalData.districts.find(item => {
          return item.id === this.initRegionalData.district;
        });
      }
      if (this.initRegionalData.village != null) {
        this.$refs.regionalForm.village = this.initRegionalData.villages.find(item => {
          return item.id === this.initRegionalData.village;
        });
      }
    },
  },
};
</script>

<style scoped></style>
